<template>
  <div class="page">

    <div class="card">
      <img src="@/assets/img/logo.png" width="60" />
      <div class="card-title">{{ $t('aiLogin.title') }}</div>
      <div>{{ $t('aiLogin.label') }}</div>
      <div class="q-mt-lg">{{ $t('aiLogin.account') }}</div>
      <div class="input-wrap q-mt-xs">
        <q-input borderless v-model="account" dense :input-style="{ color: '#fff' }" :placeholder="$t('aiLogin.placeholder1')" />
      </div>
      <div class="q-mt-md">{{ $t('aiLogin.password') }}</div>
      <div class="input-wrap q-mt-xs">
        <q-input borderless v-model="password" type="password" dense :input-style="{ color: '#fff' }" :placeholder="$t('aiLogin.placeholder2')" />
      </div>
      <div class="q-mt-md row items-center">
        <q-checkbox v-model="check" color="light-green-9" />
        <div @click="check = !check">{{ $t('aiLogin.check') }}</div>
      </div>
      <div class="btn q-mt-md" @click="submit">{{ $t('aiLogin.submit') }}</div>
      <div class="q-mt-md text-center">Copyright © 2024 CITADEL AI</div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  setup() {
    return {
      us: ref(null),
      account: ref(""),
      password: ref(""),
      check: ref(true)
    }
  },
  created() {
    this.us = JSON.parse(this.$utils.getloc("us"))
    if(this.$utils.getloc('check') == 1) {
      this.account = this.$utils.getloc('tradAccount')
      this.password = this.$utils.getloc('tradPassword')
      this.check = true
    } else {
      this.check = false
    }
  },
  methods: {
    submit() {
      let _this = this
      if(this.account == '') {
        _this.$q.dialog({
					message: _this.$t('aiLogin.placeholder1'),
				});
        return
      }
      if(this.password == '') {
        _this.$q.dialog({
					message: _this.$t('aiLogin.placeholder2'),
				});
        return
      }
      _this.$request.post(
        "api/AccountUser/LoginAccount",
        {
          token: _this.$utils.getloc("token"),
          userid: _this.us.userid,
          account: _this.account,
          password: _this.password,
        },
        (res) => {
          _this.$q.dialog({
            message: res.data.msg,
          });
          if(res.data.code == 100) {
            _this.$utils.setloc('check', _this.check ? 1 : 0)
            _this.$utils.setloc('tradAccount', _this.account)
            _this.$utils.setloc('tradPassword', _this.password)
            _this.$router.push('Trading')
          }
        }
      )
    }
  }
}
</script>

<style scoped>
.page {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url('~@/assets/img/base/auth-background.jpg') no-repeat;
  background-size: cover;
}

.card {
  width: 340px;
  padding: 20px;
  border-radius: 10px;
  color: #9ca3af;
  background-color: #1F2937;
}

.card-title {
  color: #fff;
  font-size: 24px;
}

.input-wrap {
  padding: 0 10px;
  border-radius: 6px;
  border: 1px solid #4B5563;
}

.btn {
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 10px;
  background-color: #65A30D;
}

.page :deep(.q-checkbox__bg) {
  border: 1px solid #4B5563;
}
</style>